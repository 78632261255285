import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import messages from './addToCart.lang.js';
import {application, eventEmitter} from '../../common';
import axios from 'axios'

const AddToCartPopup = ({
                          isActive = false,
                          productId,
                          imageUrl,
                          productName,
                          productCode,
                          isAvailable = true,
                          price,
                          discountPrice,
                          quantity = 1,
                          onClose,
                          onCheckout,
                          fuserId
                        }) => {

  const [showPopup, setShowPopup] = useState(false);
  const [maxProductQuantity, setMaxProductQuantity] = useState(1);

  //Добавление товара в корзину
  useEffect(() => {
    // Запрос будет выполняться только когда isActive меняется с false на true
    if (isActive) {

      // mulisearch tracking
      try {
        eventEmitter.emit('MSAddClickHistory', { productId: productCode })
        eventEmitter.emit('MSClick', { productId: productCode })
      } catch (reason) {
        console.log(reason)
      }

      const config = {headers: {'X-Requested-With': 'XMLHttpRequest'}};

      let formData = new FormData();
      formData.append('productId', productId);
      formData.append('fuserId', fuserId); // Убедитесь, что у вас есть доступ к fuserId
      formData.append('sessid', application.getSessionId());
      formData.append('quantity', 1);
      formData.append('SALE_PRODUCT_QUANTITY_UPDATE', 'Y');
      formData.append('type', 'add2basket');
      formData.append('siteId', application.getSiteId())
      formData.append('lang', application.getLanguageId())

      axios.post('/api/addtobasket/addProductToBasketAction/', formData, config).
          then(response => {
            if (response.data) {
              // Обработка результата
              setMaxProductQuantity(response.data?.BASE_PRODUCT_PARAMS?.QUANTITY);
              setProductQuantity(response.data?.BASKET_ITEM_QUANTITY);
              setShowPopup(true)
              eventEmitter.emit('OnBasketChange');
            }
          })
          .catch(error => {
            console.error('Ошибка запроса:', error);
          });
    }
  }, [isActive]);

  // State for quantity management
  const [productQuantity, setProductQuantity] = React.useState(quantity);
  
  const actualPrice = discountPrice ? discountPrice : price;

  // Calculate total based on quantity
  const total = actualPrice * productQuantity;

  // Format price (assuming price is a number)
  const formatPrice = (value) => {
    return `${value} грн.`;
  };

  // Handle quantity changes
  const decrementQuantity = () => {
    if (productQuantity > 1) {
      const newQuantity = productQuantity - 1;
      setProductQuantity(newQuantity);

      // Send request with updated quantity
      updateBasket(newQuantity);
    }
  };

  const incrementQuantity = () => {
    if (productQuantity < maxProductQuantity) {
      const newQuantity = productQuantity + 1;
      setProductQuantity(newQuantity);

      // Send request with updated quantity
      updateBasket(newQuantity);
    }
  };

  // Helper function to send basket update request
  const updateBasket = (quantity) => {
    const config = {headers: {'X-Requested-With': 'XMLHttpRequest'}};

    let formData = new FormData()
    formData.append('productId', productId) // Use productId from props
    formData.append('fuserId', fuserId) // You might need to add this to component props
    formData.append('sessid', application.getSessionId())
    formData.append('quantity', quantity)
    formData.append('siteId', application.getSiteId())
    formData.append('lang', application.getLanguageId())

    // Отправляем запрос на добавление в корзину
    axios.post('/api/addtobasket/changeProductQuantityBasketAction/', formData, config).
        then(response => {
          setMaxProductQuantity(response.data?.BASE_PRODUCT_PARAMS?.QUANTITY);
          eventEmitter.emit('OnBasketChange');
        }).
        catch(error => {
          console.error(error)
        });
  }

  function getMessage(code) {
    return messages[`${application.getLanguageId()}`].hasOwnProperty(code) ? messages[`${application.getLanguageId()}`][code] : '';
  }

  return (
      <div className={`popup popup-add-cart ${showPopup && isActive ? 'active' : ''}`} id={`product_popup_add_cart_${productId}`}>
        <div className="popup__overlay"></div>
        <div className="popup__holder popup__holder--add-cart">
          <div className="popup__close" onClick={onClose}>
            <svg>
              <use xmlns="http://www.w3.org/1999/xlink" xlinkHref="/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div className="add-cart">
            <div className="add-cart__image">
              <img src={imageUrl} alt={productName} />
            </div>
            <div className="add-cart__content">
              {isAvailable && (
                  <div className="container basket item" style={{ visibility: 'hidden' }}>
                    <div data-productkey={productId}></div>
                  </div>
              )}
              <div className="add-cart__title">{productName}</div>
              <div className="add-cart__status">
                <span></span> {isAvailable ? getMessage('ADD_CART_STATUS_OK'): getMessage('BASKET_UNKNOWN_ERROR')}
              </div>
              <div className="add-cart__article">
                {getMessage('ADD_CART_ARTICLE')}: <span>{productCode}</span>
              </div>

              {isAvailable && (
                  <div id="add-cart__options" className="add-cart__options">
                    <div className="add-cart__options-item">
                      <div className="add-cart__options-text">{getMessage(
                          'ADD_CART_PRICE_TITLE')}:
                      </div>
                      <div className="add-cart__options-value">
                        {discountPrice ? (
                            <>
                              <div className="product__price-new">{formatPrice(
                                  discountPrice)}</div>
                              <div className="product__price-old">{formatPrice(
                                  price)}</div>
                            </>
                        ) : (
                            <div className="price">{formatPrice(price)}</div>
                        )}
                      </div>
                    </div>
                    <div className="add-cart__options-item">
                      <div className="add-cart__options-text">{getMessage(
                          'ADD_CART_QUANTITY_TITLE')}:
                      </div>
                      <div className="add-cart__options-value">
                        <div className="product-quantity">
                          <button
                              className="product-quantity__button product-quantity__minus"
                              disabled={productQuantity <= 1}
                              onClick={decrementQuantity}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="5"
                                 height="6" viewBox="0 0 5 6">
                              <path
                                  d="M547,1782.72l-1.894-2.94,1.894-2.63-0.173-.15-4.83,2.78,4.97,3.22Z"
                                  transform="translate(-542 -1777)"></path>
                            </svg>
                          </button>
                          <input
                              type="number"
                              readOnly
                              name="product-quantity"
                              value={productQuantity}
                              min="1"
                              max={maxProductQuantity}
                              className="product-quantity__input"
                          />
                          <button
                              id="product-quantity__plus"
                              className="product-quantity__button product-quantity__plus"
                              style={{display: 'block'}}
                              disabled={productQuantity >= maxProductQuantity}
                              onClick={incrementQuantity}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="5"
                                 height="6" viewBox="0 0 5 6">
                              <path
                                  d="M547,1782.72l-1.894-2.94,1.894-2.63-0.173-.15-4.83,2.78,4.97,3.22Z"
                                  transform="translate(-542 -1777)"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="add-cart__options-item">
                      <div className="add-cart__options-text">Итого:</div>
                      <div className="add-cart__options-value">
                        <div className="total">{formatPrice(total)}</div>
                      </div>
                    </div>
                    {Number(productQuantity) === Number(maxProductQuantity) && (
                        <div id="full_elm" className="maximum">
                          <p className="error text-[70%] w-[300px]">{getMessage('ADD_CART_QUANTITY_LIMITED')}</p>
                        </div>
                    )}
                  </div>
              )}

              <div className="add-cart__buttons">
                <a
                    href="#"
                    className="white-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      onClose();
                    }}
                >
                  {getMessage('BTN_MESSAGE_CLOSE_POPUP')}
                </a>
                {isAvailable && (
                    <a
                        href={application.getLanguageId() !== 'ru'
                            ? `/${application.getLanguageId()}/personal/order/make/`
                            : "/personal/order/make/"}
                        className="black-btn"
                        onClick={(e) => {
                          if (onCheckout) {
                            e.preventDefault();
                            onCheckout();
                          }
                        }}
                    >
                      {getMessage('ADD_CART_BUTTON_ORDER')}
                    </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

AddToCartPopup.propTypes = {
  isActive: PropTypes.bool,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageUrl: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productCode: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool,
  price: PropTypes.number,
  discountPrice: PropTypes.number,
  quantity: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onCheckout: PropTypes.func,
  messages: PropTypes.string,
  fuserId: PropTypes.number,
};

export default AddToCartPopup;